<template>
  <visualize :place="'forecast'"></visualize>
</template>

<script>
import Visualize from "@/core/views/MapView.vue";

export default {
  name: "ForecastView",
  components: { Visualize }
};
</script>

<style scoped></style>
